import React, { createContext, useState, useContext, useEffect } from "react";
import {
  getCurrentUser,
  signIn,
  signOut,
  fetchAuthSession,
} from "aws-amplify/auth"; // Added fetchAuthSession for debugging

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        // First, try to get the current authenticated user
        const user = await getCurrentUser();
        console.log("Current user: ", user);

        if (user) {
          setIsAuthenticated(true);
        } else {
          // If getCurrentUser fails, check the session directly
          console.log("User not authenticated, checking session.");
          const session = await fetchAuthSession(); // Check session if no user is found
          console.log("Session tokens: ", session.tokens);
          setIsAuthenticated(true); // If session exists, user is authenticated
        }
      } catch (error) {
        console.log("Error fetching session or user: ", error);
        setIsAuthenticated(false); // No session or user means unauthenticated
      } finally {
        setLoading(false); // Stop the loading state
      }
    };

    checkAuthState(); // Run on app load to check user session
  }, []);

  const login = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    try {
      const user = await signIn({ username, password }); // Call signIn
      console.log("User after login: ", user);
      console.log("LocalStorage after login: ", localStorage);
      setIsAuthenticated(true); // Set user as authenticated
      return true;
    } catch (error) {
      console.error("Login error: ", error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await signOut(); // Call signOut
      setIsAuthenticated(false); // Reset authentication state on logout
    } catch (error) {
      console.error("Logout error: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Display loading state while checking authentication
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
