import React, { useState } from "react";
import { Filters } from "../utils/interfaces";
import { DownloadAdoptionForm } from "../elements/DownloadAdoptionForm";

const PetsToAdopt = () => {
  const [filters, setFilters] = useState<Filters>({
    type: "",
    age: "",
    gender: "",
    size: "",
    tags: [],
  });

  const petsToAdopt = [
    {
      id: 1,
      name: "Beats",
      href: "#",
      price: "$256",
      options: "Cat, Young, Male, Medium",
      description:
        "Beats is a very soft, cuddly, chatty, ADORABLE boy!!  He is great with all other cats, sociable and just lovely.",
      imageSrc: "https://picsum.photos/400/600",
      imageAlt:
        "Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.",
      tags: ["Dog Friendly", "Kid Friendly"],
    },
    {
      id: 2,
      name: "Ora",
      href: "#",
      price: "$256",
      options: "Cat, Young, Male, Medium",
      description:
        "Beats is a very soft, cuddly, chatty, ADORABLE boy!!  He is great with all other cats, sociable and just lovely.",
      imageSrc: "https://picsum.photos/400/600",
      imageAlt:
        "Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.",
      tags: ["Dog Friendly", "Cat Friendly", "Kid Friendly"],
    },
    {
      id: 3,
      name: "Andro",
      href: "#",
      price: "$256",
      options: "Cat, Young, Male, Medium",
      description:
        "Beats is a very soft, cuddly, chatty, ADORABLE boy!!  He is great with all other cats, sociable and just lovely.",
      imageSrc: "https://picsum.photos/400/600",
      imageAlt:
        "Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.",
      tags: ["Cat Friendly"],
    },
    {
      id: 4,
      name: "Beats",
      href: "#",
      price: "$256",
      options: "Cat, Young, Male, Medium",
      description:
        "Beats is a very soft, cuddly, chatty, ADORABLE boy!!  He is great with all other cats, sociable and just lovely.",
      imageSrc: "https://picsum.photos/400/600",
      imageAlt:
        "Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.",
      tags: ["Kid Friendly"],
    },
  ];

  // todo - this is crude and placeholder
  const filteredPets = petsToAdopt.filter((pet) => {
    const [type, age, gender, size] = pet.options.split(", ");
    return (
      (!filters.type || type === filters.type) &&
      (!filters.age || age === filters.age) &&
      (!filters.gender || gender === filters.gender) &&
      (!filters.size || size === filters.size) &&
      (filters.tags.length === 0 ||
        filters.tags.every((tag) => pet.tags.includes(tag)))
    );
  });

  const handleFilterChange = (category: keyof Filters, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [category]:
        category === "tags"
          ? prevFilters.tags.includes(value)
            ? prevFilters.tags.filter((tag) => tag !== value)
            : [...prevFilters.tags, value]
          : value,
    }));
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24">
        <DownloadAdoptionForm />
        <h2 className="text-2xl font-bold mb-8">Filter Pets</h2>

        <div className="flex flex-wrap gap-4 mb-8">
          <select
            className="px-3 py-2 border rounded-md"
            value={filters.type}
            onChange={(e) => handleFilterChange("type", e.target.value)}
          >
            <option value="">All Types</option>
            <option value="Cat">Cat</option>
            <option value="Dog">Dog</option>
          </select>

          <select
            className="px-3 py-2 border rounded-md"
            value={filters.age}
            onChange={(e) => handleFilterChange("age", e.target.value)}
          >
            <option value="">All Ages</option>
            <option value="Young">Young</option>
            <option value="Adult">Adult</option>
            <option value="Senior">Senior</option>
          </select>

          <select
            className="px-3 py-2 border rounded-md"
            value={filters.gender}
            onChange={(e) => handleFilterChange("gender", e.target.value)}
          >
            <option value="">All Genders</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>

          <select
            className="px-3 py-2 border rounded-md"
            value={filters.size}
            onChange={(e) => handleFilterChange("size", e.target.value)}
          >
            <option value="">All Sizes</option>
            <option value="Small">Small</option>
            <option value="Medium">Medium</option>
            <option value="Large">Large</option>
          </select>
        </div>

        <div className="flex flex-wrap gap-2 mb-8">
          {["Dog Friendly", "Cat Friendly", "Kid Friendly"].map((tag) => (
            <button
              key={tag}
              className={`px-3 py-1 rounded-full text-sm ${
                filters.tags.includes(tag)
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => handleFilterChange("tags", tag)}
            >
              {tag}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {filteredPets.map((pet) => (
            <div
              key={pet.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
            >
              <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                <img
                  alt={pet.imageAlt}
                  src={pet.imageSrc}
                  className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <a href={pet.href}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {pet.name}
                  </a>
                </h3>
                <p className="text-sm text-gray-500">{pet.description}</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {pet.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 text-xs font-semibold text-gray-700 bg-emerald-300 rounded-full mt-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="flex flex-1 flex-col justify-end">
                  <p className="text-sm italic text-gray-500 my-2">
                    {pet.options}
                  </p>
                  <button
                    type="button"
                    className="rounded-md bg-indigo-600 px-16 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-100"
                  >
                    Adopt Me
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PetsToAdopt;
