import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: "eu-west-1_6hlxIG6Xk",
      userPoolClientId: "7ua6tb03k89rds882dlio5k021",
      loginWith: {
        email: true,
        phone: false,
        username: false,
      },
    },
  },
  region: "eu-west-1",
});
