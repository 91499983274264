import React from "react";
import { Link } from "react-router-dom";
import {
  DocumentArrowDownIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

const HowToAdopt = () => {
  return (
    <div className="bg-gradient-to-br from-indigo-100 to-purple-100 py-16 min-h-screen">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-indigo-900 text-center mb-8 shadow-text">
          Form Downloads
        </h1>

        <div className="bg-white shadow-2xl rounded-2xl overflow-hidden border border-indigo-200">
          <div className="p-6 sm:p-10">
            <p className="text-xl text-indigo-800 mb-6 leading-relaxed">
              Here are all our{" "}
              <span className="font-semibold text-indigo-600">adoption</span>,{" "}
              <span className="font-semibold text-indigo-600">foster</span> and{" "}
              <span className="font-semibold text-indigo-600">
                volunteering forms
              </span>{" "}
              for you to download as needed.
            </p>

            <div className="bg-indigo-50 rounded-xl p-6 mb-8 shadow-inner">
              <p className="text-gray-700 mb-4 flex items-center">
                <DocumentArrowDownIcon className="h-6 w-6 text-indigo-500 mr-2" />
                There are three different document formats – PDF, Word (.xdoc)
                and Open Document (.odt).
              </p>

              <p className="text-gray-700 mb-4 flex items-center">
                <EnvelopeIcon className="h-6 w-6 text-indigo-500 mr-2" />
                Please email your completed and signed form to{" "}
                <a
                  href="mailto:info@cypruspridehouse.org"
                  className="text-indigo-600 hover:underline ml-1"
                >
                  info@cypruspridehouse.org
                </a>
                .
              </p>

              <p className="text-gray-700 flex items-center">
                <ExclamationTriangleIcon className="h-6 w-6 text-indigo-500 mr-2" />
                Any problems downloading or completing the forms, please get in
                touch.
              </p>
            </div>

            <div className="space-y-8">
              {[
                { title: "Adoption Form", color: "indigo" },
                { title: "Foster Agreement", color: "indigo" },
                { title: "Volunteer Assistance Form", color: "indigo" },
              ].map((form, index) => (
                <div
                  key={index}
                  className={`bg-${form.color}-50 rounded-xl p-6 shadow-md transition-all duration-300 hover:shadow-lg`}
                >
                  <h2
                    className={`text-2xl font-semibold text-${form.color}-900 mb-4`}
                  >
                    {form.title}
                  </h2>
                  <div className="flex flex-wrap gap-4">
                    {["PDF", "Word", "Open Document"].map((format, idx) => (
                      <Link
                        key={idx}
                        to="#"
                        className={`px-6 py-3 bg-${form.color}-600 text-white rounded-full hover:bg-${form.color}-700 transition duration-300 shadow-md hover:shadow-lg flex items-center`}
                      >
                        <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
                        {format}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToAdopt;
