import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import PetsToAdopt from "./pages/PetsToAdopt";
import OurStory from "./pages/OurStory";
import HowToAdopt from "./pages/HowToAdopt";
import ContactUs from "./pages/ContactUs";
import Billy from "./pages/Billy";
import { AuthProvider } from "./contexts/AuthContext";
import { useAuth } from "./contexts/AuthContext";
import Layout from "./pages/Layout";
import "./App.css";

const ProtectedAdminRoute = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Admin /> : <Navigate to="/login" replace />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/pets-to-adopt" element={<PetsToAdopt />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/how-to-adopt" element={<HowToAdopt />} />
            <Route path="/billy" element={<Billy />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/admin" element={<ProtectedAdminRoute />} />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
