import React from "react";
import { HeroWelcome } from "../elements/HeroWelcome";
import { AdoptionHighlights } from "../elements/AdoptionHighlights";
import { YouTube } from "../elements/YouTube";

const Home = () => {
  return (
    <div className="bg-white">
      <HeroWelcome />
      <AdoptionHighlights />
      <YouTube url="https://www.youtube.com/embed/RKm8tmpLUME?si=B23xCUNOB1E7RqaN" />
    </div>
  );
};

export default Home;
