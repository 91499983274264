import React from "react";
import {
  ChatBubbleLeftIcon,
  CreditCardIcon,
  HeartIcon,
} from "@heroicons/react/20/solid";
import dogCat from "../assets/dog-cat.jpg";

const cards = [
  {
    name: "Adopt",
    description:
      "Adoption is straightforward. Click here to provide a cat or dog a forever home.",
    icon: HeartIcon,
  },
  {
    name: "Donate",
    description:
      "Every penny goes towards the animals. Click here to donate to Cyprus Pride House.",
    icon: CreditCardIcon,
  },
  {
    name: "More ways to help",
    description:
      "Click here to find out about how to foster or sponsor a cat or dog.",
    icon: ChatBubbleLeftIcon,
  },
];

export const HeroWelcome = () => {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <div className="absolute inset-0 -z-10">
        <img
          alt=""
          src={dogCat}
          className="h-full w-full object-cover object-center md:object-center"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-40"></div>
      </div>

      <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl">
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu">
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Where cats and dogs find their forever homes
          </h2>
          <h3 className="mt-4 text-xl leading-7 text-gray-300">
            Hello, and welcome to Cyprus Pride House
          </h3>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Cyprus Pride House was established in 2002. Since then, June has
            rescued over a thousand abandoned, abused and neglected cats and
            dogs. The goal is to find our pets forever homes in the UK/EU.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            CPH is a small, not-for-profit animal rescue based in the Troodos
            Mountains near Limassol, Cyprus and is run by just one person, who
            does as much as they can with support from donations and people
            adopting.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
          {cards.map((card) => (
            <div
              key={card.name}
              className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10"
            >
              <card.icon
                aria-hidden="true"
                className="h-7 w-5 flex-none text-indigo-400"
              />
              <div className="text-base leading-7">
                <h3 className="font-semibold text-white">{card.name}</h3>
                <p className="mt-2 text-gray-300">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
