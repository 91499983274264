import React from "react";

export const DownloadAdoptionForm = () => {
  return (
    <div className="text-center mb-16">
      <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
        <span className="block xl:inline">Find Your Perfect</span>{" "}
        <span className="block text-indigo-600 xl:inline">Furry Friend</span>
      </h1>
      <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        Start your adoption journey today. Download our adoption form and give a
        loving home to a pet in need.
      </p>
      <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
        <button className="group relative inline-flex items-center justify-center overflow-hidden rounded-full bg-gradient-to-br from-blue-400 to-blue-600 p-0.5 text-sm font-medium text-gray-900 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-200 group-hover:from-blue-400 group-hover:to-blue-600">
          <span className="relative rounded-full bg-white px-5 py-2.5 transition-all duration-75 ease-in group-hover:bg-opacity-0">
            <svg
              className="mr-2 -ml-1 w-5 h-5 inline-block text-blue-500 group-hover:text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            <span className="text-blue-500 group-hover:text-white">
              Download Adoption Form
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};
