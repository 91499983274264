import React from "react";

interface YouTubeProps {
  url: string;
}

export const YouTube = (props: YouTubeProps) => {
  return (
    <div className="aspect-w-16 aspect-h-9 mx-4 md:mx-8">
      <iframe
        title="Cyprus Pride House"
        src={props.url}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
