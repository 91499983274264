import React from "react";
import { useNavigate } from "react-router-dom";

interface QuickLink {
  name: string;
  path: string;
  icon: string;
}

const QuickLinks: React.FC = () => {
  const navigate = useNavigate();

  const links: QuickLink[] = [
    { name: "FAQs", path: "/faqs", icon: "❓" },
    { name: "Pets needing homes", path: "/pets-to-adopt", icon: "🐾" },
    { name: "The adoption process", path: "/adoption-process", icon: "📝" },
  ];

  const handleClick = (path: string): void => {
    navigate(path);
  };

  return (
    <div className="my-8">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">Quick Links:</h3>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        {links.map((link) => (
          <button
            key={link.name}
            onClick={() => handleClick(link.path)}
            className="flex items-center justify-center p-4 bg-indigo-50 border-2 border-indigo-200 rounded-lg shadow-md hover:bg-indigo-100 hover:border-indigo-300 transition-all duration-200 ease-in-out group"
          >
            <span className="text-3xl mr-3 group-hover:scale-110 transition-transform duration-200">
              {link.icon}
            </span>
            <span className="text-indigo-700 font-medium group-hover:underline">
              {link.name}
            </span>
            <svg
              className="w-4 h-4 ml-2 text-indigo-500 group-hover:translate-x-1 transition-transform duration-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        ))}
      </div>
    </div>
  );
};

export default QuickLinks;
