import React, { useState } from "react";
import { Link } from "react-router-dom";

type TabContent = "story" | "facts" | "gallery";

const Billy = () => {
  const [activeTab, setActiveTab] = useState<TabContent>("story");

  const tabContent = {
    story: (
      <div className="animate-fade-in">
        <p className="text-lg leading-8 text-gray-700">
          When I first moved to Cyprus in 2002, there were three cats living in
          the garden – Billy, his brother Chan and their mum Misty. I
          immediately started to care for them. Billy and Chan were just 2
          months old when Misty was sterilised so she couldn&apos;t have more.
        </p>
        <p className="mt-4 text-lg leading-8 text-gray-700">
          At first, Billy had a very bad cold and needed treatment. He got
          better and grew up into a very tall and large boy – at 3 years of age
          he was 8 kilos!
        </p>
      </div>
    ),
    facts: (
      <div className="grid grid-cols-2 gap-4 animate-fade-in">
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="font-semibold text-indigo-600">Age</h3>
          <p className="text-3xl font-bold text-gray-800">16+ years</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="font-semibold text-indigo-600">Max Weight</h3>
          <p className="text-3xl font-bold text-gray-800">8 kg</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="font-semibold text-indigo-600">Favorite Food</h3>
          <p className="text-3xl font-bold text-gray-800">Tuna</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="font-semibold text-indigo-600">Personality</h3>
          <p className="text-3xl font-bold text-gray-800">Affectionate</p>
        </div>
      </div>
    ),
    gallery: (
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 animate-fade-in">
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <img
            key={i}
            src={`https://source.unsplash.com/300x300/?cat&sig=${i}`}
            alt={`Billy ${i}`}
            className="w-full h-48 object-cover rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
          />
        ))}
      </div>
    ),
  };

  return (
    <main className="bg-violet-50		 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12 animate-fade-in-down">
          <h1 className="text-5xl md:text-7xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Meet Billy 2
          </h1>
          <p className="mt-4 text-xl text-gray-600">
            Our beloved mascot and heart of Cyprus Pride House
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-8 items-center mb-12">
          <img
            src="https://cypruspridehouse.org/wp-content/uploads/Billy7.jpg"
            alt="Billy the cat"
            className="w-full md:w-1/2 rounded-xl shadow-2xl animate-fade-in-left"
          />
          <div className="w-full md:w-1/2 animate-fade-in-right">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              Billy&apos;s Journey
            </h2>
            <p className="text-lg text-gray-600 mb-4">
              From a garden kitten to the face of our organization, Billy&apos;s
              story is one of resilience, love, and the transformative power of
              care.
            </p>
            <div className="flex flex-wrap justify-center md:justify-start gap-4 mt-6">
              {(Object.keys(tabContent) as TabContent[]).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`
                  px-6 py-3 rounded-full text-sm font-semibold uppercase tracking-wider
                  transition-all duration-300 ease-in-out
                  ${
                    activeTab === tab
                      ? "bg-gradient-to-r from-purple-500 to-indigo-600 text-white shadow-lg transform scale-105"
                      : "bg-white text-indigo-600 hover:bg-indigo-50 hover:shadow-md"
                  }
                `}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-xl p-8 mb-12">
          {tabContent[activeTab]}
        </div>

        <div className="text-center animate-fade-in-up">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Support Billy & Friends
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            Help us continue providing care for Billy and other cats in need.
          </p>
          <Link
            to="#"
            className="inline-block bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-bold py-3 px-8 rounded-full hover:from-purple-600 hover:to-indigo-700 transition-all duration-300 transform hover:scale-105"
          >
            Donate Now
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Billy;
